<template>
    <div  class="talentRecruitmentDetial">
        <!-- 头部 -->
        <headers :haveEmpty="true"></headers>
        <!-- 面包屑 -->
        <crumbs :listData="listData"></crumbs>
        <!-- 内容 -->
        <div class="contents">
            <!-- 头部内容 -->
            <div class="tops">
                <div class="leftCon">
                    <div class="namesBox">
                        <div class="status">急</div>
                        <div class="name">{{info.titile}}</div>
                    </div>
                    <div class="tips">
                        <div class="money">{{info.salary}}</div>
                        <div class="type">{{info.nature}}</div>
                    </div>
                    <div class="dates">发布于2023/05/18</div>
                </div>
                <div class="bths" @click="clickZw">申请职位</div>
            </div>
            <!-- 详情 -->
            <div class="infos">
                <div class="title">职位描述</div>
                <div class="Cons">
                    <div class="text1">岗位职责:</div>
                    <!-- {{info.conter}} -->
                    <div class="text2" v-html="info.conter"></div>
                </div>
            </div>
        </div>
        <!-- 弹框 -->
        <el-dialog
        :visible.sync="dialogVisible"
        width="30%"
        :append-to-body="true"
        :before-close="handleClose"
        top="30vh">
            <div class="dialogs">
                <div class="items">
                    <div class="title">猎头专线：</div>
                    <div class="detial">0351-7120660<br/>15513031937</div>
                </div>
                <div class="items">
                    <div class="title">猎头邮箱：</div>
                    <div class="detial">liyaling@sxgkj.com</div>
                </div>
            </div>
        </el-dialog>
        <!-- 底部 -->
        <footers :bg="'#EFF0F0'"></footers>
    </div>  
</template>

<script>
import headers from '../../components/header.vue'
import crumbs from '../../components/crumbs.vue'
import footers from '../../components/footers.vue'
export default {
    components:{
        headers,
        crumbs,
		footers
    },
    data(){
        return{
            listData:['首页','人才招聘','职位详情'],
            info:{},
            // 弹框
            dialogVisible:false,
        }
    },
    methods:{
        // 点击职位
        clickZw(){
            this.dialogVisible = true;
        },
    },
    created(){
        this.info = JSON.parse(sessionStorage.getItem('activeJob'));
        console.log(this.info)
    },
}
</script>

<style lang="less" scoped>
.talentRecruitmentDetial{
    width: 100%;
	min-height: 100vh;
	background-color: #EFF0F0;
	box-sizing: border-box;
    .contents{
        width: 1240px;
        height: auto;
        background-color: #FFFFFF;
        margin: 59px auto;
        .tops{
            width: 100%;
            padding: 56px 30px 30px 30px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 2px solid #DCDDDD;
            box-sizing: border-box;
            .leftCon{
                flex: 1;
                .namesBox{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .status{
                        width: 28px;
                        height: 28px;
                        background-color: #EE7470;
                        text-align: center;
                        line-height: 28px;
                        font-size: 16px;
                        color: #FFFFFF;
                        border-radius: 4px;
                    }
                    .name{
                        font-size: 34px;
                        color: #3E3E3E;
                        margin-left: 10px;
                    }
                }
                .tips{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 22px;
                    color: #3E3E3E;
                    margin-top: 35px;
                    .money{
                        display: flex;
                        align-items: center;
                        &::after{
                            content: '';
                            display: block;
                            width: 1px;
                            height: 22px;
                            background-color: #3E3E3E;
                            margin-left: 18px;
                            margin-right: 18px;
                        }
                    }
                }
                .dates{
                    font-size: 22px;
                    color: #3E3E3E;
                    margin-top: 25px;
                }
            }
            .bths{
                width: 240px;
                height: 80px;
                border-radius: 40px;
                background: linear-gradient(90deg, #0068B7 0%, #00B2C1 100%);
                text-align: center;
                line-height: 80px;
                font-size: 22px;
                color: #FFFFFF;
                cursor: pointer;
            }
        }
        .infos{
            width: 100%;
            height: auto;
            padding: 25px 41px;
            box-sizing: border-box;
            .title{
                width: 100%;
                font-size: 26px;
                color: #3E3E3E;
            }
            .Cons{
                width: 100%;
                margin-top: 30px;
                .text1{
                    width: 100%;
                    font-size: 22px;
                    color: #3E3E3E;
                }
                .text2{
                    font-size: 22px;
                    color: #3E3E3E;
                }
            }
        }
    }
    
}
</style>
<style lang="less">
.dialogs{
        width: 380px;
        display: flex;
        flex-direction: column;
        font-size: 26px;
        color: #3E3E3E;
        margin: 0 auto;
        padding: 60px 20px;
        .items{
            width: 100%;
            height: auto;
            display: flex;
            margin-top: 20px;
            line-height: 30px;
            &:nth-child(1){
                margin-top: 0;
            }
            .title{
                flex: none;
            }
            .detial{
                flex: 1;
            }
        }
    }
    .el-dialog__header{
        border: none;
    }   
</style>